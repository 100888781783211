import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_9.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-06-12',
  year: '2016',
  month: '6',
  title: `Durch systemisches Coaching Schätze finden und heben (9/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 9: Gesundheits- und Balancekompetenz leben. Wie systemisches FrauenCoaching sie dabei unterstützen kann, ihre persönliche Balance zu leben.`,
  url: 'durch-systemisches-frauencoaching-schaetze-finden-und-heben-9-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Identität',
    'Sinn',
    'Energiespeicher',
    'Werte',
    'Ziele',
    'Verhalten',
    'Bewusstsein',
    'Strukturen',
    'Stressoren',
    'Balance',
    'Gesundheit',
  ],
  linkedBlogEntryIndexes: ['E12', 'E13', 'E14'],
}

export default BlogEntry
