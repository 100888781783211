import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/buddha.png'

const BlogEntry: FullBlogEntryData = {
  date: '2017-12-03',
  year: '2017',
  month: '12',
  title: `Methodenkoffer: Das Systembrett`,
  teaser: `Klarheit und Handlungskompetenz durch Visualisierung.`,
  url: 'methodenkoffer-das-systembrett',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'verdeckte Aufstellungen',
    'Ressourcen',
    'Lösungsmodus',
    'Sekundärgewinn',
    'Perspektive',
    'zirkuläre Fragen',
    'direkte Fragen',
    'Stellvertreter',
    'Gefühle',
    'Visualisierung',
    'Familienbrett',
    'Systembrett',
  ],
  linkedBlogEntryIndexes: ['E17', 'E14', 'E3'],
}

export default BlogEntry
