import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/psychologische_beratung.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-08-18',
  year: '2015',
  month: '8',
  title: `Wie arbeitet eigentlich ein systemischer Coach/Therapeut?`,
  teaser: `Die systemische Herangehensweise zeichnet sich durch eine besondere Werthaltung gegenüber den Klien_innen aus. Insgesamt wird auf Muster und Zusammenhänge des Verhaltens von Menschen in  Gruppen geschaut. Man geht davon aus, dass sich Verhalten immer nach dem jeweiligen Kontext richtet. Aus Sicht der systemischen Therapie liegen die Probleme weniger in der handelnden Person allein, sondern stehen immer in familiären, sozialen, kulturellen Zusammenhängen. Dabei werden Symptome immer als Lösungsversuche gesehen. Die systemische Beratung ermöglicht die Wahrnehmung neuer, bisher unbekannter Perspektiven und Verständnis für die Haltung der übrigen Beteiligten. Es geht immer darum, Maßnahmen für das Leben der Klient_innen zu erarbeiten.`,
  url: 'wie-arbeitet-eigentlich-ein-systemischer-coach-therapeut',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Sichtweisen',
    'TimeLine',
    'Familienbrett',
    'Genogramm',
    'Motivation',
    'Herausforderungen',
    'Wertschätzung',
    'Ressourcen',
    'Coach',
    'Systemik',
    'Symptome',
    'Lösungsversuch',
    'Allparteilichkeit',
    'Authentizität',
    'Probleme',
  ],
  linkedBlogEntryIndexes: ['E3', 'E16', 'E17']
}

export default BlogEntry
