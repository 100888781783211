import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_4.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-11-29',
  year: '2015',
  month: '11',
  title: `Durch systemisches Coaching Schätze finden und heben (4/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 4: Kommunikationskompetenzen erlernen und einsetzen. Wie systemisches FrauenCoaching sie dabei unterstützen kann, Ihre persönliche Komminikationskompetenz zu schärfen und dadurch ihre Ziele schneller und besser zu erreichen und ihre Beziehungen zu verbessern.`,
  url: 'durch-systemisches-frauencoaching-schaetze-finden-und-heben-4-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Systemische Sicht',
    'Durchsetzen',
    'Verhandeln',
    'Körpersprache',
    'Kommunikationskompetenz',
    'Kommunikation',
    'Systemisches Coaching',
    'FrauenCoaching',
  ],
  linkedBlogEntryIndexes: ['E7', 'E8', 'E2'],
}

export default BlogEntry
