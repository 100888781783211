import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_1.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-09-14',
  year: '2015',
  month: '9',
  title: `Durch systemisches Coaching Schätze finden und heben (1/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 1: Selbstbewusstsein. Welchen Beitrag kann systemisches Coaching zur Stärkung des Selbstbewusstseins von Frauen leisten? Generell bestimmt die Meinung, die wir über uns selbst haben, ob wir selbstbewusst oder weniger selbstbewusst auftreten. Ich weiß, was ich kann und ich muss anderen nichts beweisen. Eine Konzentration auf diese Haltung bringt genau die Attraktivität, der die Wertschätzung der anderen schließlich folgt. Und gut bin ich besser als perfekt. Das nimmt einerseits den Druck vor zu hohen Ansprüchen an sich selbst und lässt uns andererseits menschlich bleiben. Wie aber kommen wir zu einer guten Meinung über uns selbst? Und wo ist sie eventuell verloren gegangen? Nicht zuletzt, woran erkenne ich eigentlich einen selbstbewussten Menschen?`,
  url: 'durch-systemisches-coaching-schaetze-finden-und-heben-1-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Gegenbeispiel',
    'Verallgemeinerung',
    'Umdeutung',
    'Veränderung',
    'Glaubenssätze',
    'Einwände',
    'Krise',
    'Respekt',
    'Werte',
    'Wertschätzung',
    'Selbstbewusstsein',
  ],
  linkedBlogEntryIndexes: ['E4', 'E14', 'E15'],
}

export default BlogEntry
