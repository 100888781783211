import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/gesundheit_balance.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2022-03-23',
  year: '2022',
  month: '3',
  title: `Kleines 1x1 des Selbstwertgefühls und der Selbstliebe`,
  teaser: `Dein Weg über die Bewusstmachung der Symptome deiner Kindheitswunden hin zur Entscheidung der Übernahme der Verantwortung für dein Leben und zur aktiven Selbstgestaltung deines Lebens nach deinen Wünschen.`,
  url: 'kleines-1x1-des-selbstwertgefuehls-und-der-selbstliebe',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Verantwortung',
    'Opferrolle',
    'Stärken',
    'Selbstsicherheit',
    'Selbstliebe',
    'Selbstwertgefühl',
  ],
  linkedBlogEntryIndexes: ['E16', 'E17', 'E14']
}

export default BlogEntry
