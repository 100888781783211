import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_5.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-01-04',
  year: '2016',
  month: '1',
  title: `Durch systemisches Coaching Schätze finden und heben (5/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 5: Konfliktkompetenzen erlernen und Konflikte konstuktiv lösen. Wie systemisches Coaching sie dabei unterstützen kann, Konflikte konstruktiv zu lösen und sie als Chance für persönliche Entwicklung warzunehmen.`,
  url: 'durch-systemisches-frauencoaching-schaetze-finden-und-heben-5-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Sozialisation',
    'Glaubenssätze',
    'Selbstwirksamkeit',
    'Rollen',
    'Konflikte',
    'Coaching Berlin',
    'Coach Berlin',
  ],
  linkedBlogEntryIndexes: ['E8', 'E9', 'E13'],
}

export default BlogEntry
