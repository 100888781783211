import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/fragezeichen.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-07-02',
  year: '2016',
  month: '7',
  title: `Berufung und Beruf`,
  teaser: `Von Beruf und Berufung, über Kindererziehung und Gerechtigkeit, von ausgewogener Balance.`,
  url: 'berufung-und-beruf',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Durchsetzung',
    'Arbeitsteilung',
    'Kindererziehung',
    'Gefühl',
    'Werte',
    'Glaubenssätze',
    'Prioritäten',
    'Ressourcen',
    'Strukturen',
    'Ziele',
    'Rollenbilder',
    'Berufung',
    'Beruf',
  ],
  linkedBlogEntryIndexes: ['E13', 'E15', 'E1'],
}

export default BlogEntry
