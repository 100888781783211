import dynamic, { Loader } from 'next/dynamic'
import { LoadingIndicator } from 'atoms'

function blogContentDynamic(Component: Loader) {
  return dynamic(
    Component,
    {
      ssr: false,
      loading: () => (<LoadingIndicator />)
    }
  )
}

export default blogContentDynamic
