import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_6.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-03-06',
  year: '2016',
  month: '3',
  title: `Durch systemisches Coaching Schätze finden und heben (6/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 6: Gefühlskompetenz zulassen und nutzen Wie systemisches Coaching sie dabei unterstützen kann, Ihre Gefühle wahrzunehmen, wertschätzend mit ihnen umzugehen und sie positiv zu nutzen.`,
  url: 'durch-systemisches-frauencoaching-schaetze-finden-und-heben-6-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Ressource',
    'Perspektive',
    'Glaubenssätze',
    'Wahrnehmung',
    'Bewusstsein',
    'Unbewusstes',
    'Konflikte',
    'Coaching Berlin',
    'Coaching',
    'Gefühle',
  ],
  linkedBlogEntryIndexes: ['E9', 'E10', 'E12'],
}

export default BlogEntry
