import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/team.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2017-05-06',
  year: '2017',
  month: '5',
  title: `Fundament für die Gleichberechtigung von Frauen`,
  teaser: `Rechtliche und gesellschaftliche Rahmenbedingungen: Wie systemisches FrauenCoaching sie dabei unterstützen kann, trotz schwieriger gesellschaftlicher Bedingungen Ihren Weg zu gehen.`,
  url: 'fundament-fuer-die-gleichberechtigung-von-frauen',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'gleiche Bezahlung',
    'reproduktive Arbeit',
    'Finanzielle Unabhängigkeit',
    'Männlichkeitswahn',
    'Sozialisation',
    'Rollenbilder',
    'Gleichberechtigung',
    'Problemzonen',
    'Minderwertigkeit',
    'Würde',
    'Macht',
    'Prostitution',
    'Pornografie',
  ],
  linkedBlogEntryIndexes: ['E12', 'E13', 'E14'],
}

export default BlogEntry
