import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/pflanzen.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-11-05',
  year: '2016',
  month: '11',
  title: `Balancekompetenzen`,
  teaser: `Schutzschilder gegen Burn Out. Wie systemisches FrauenCoaching sie dabei unterstützen kann, sich gegen Burn Out zu wappnen.`,
  url: 'balancekompetenzen',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Down Shifting',
    'Entscheidungen',
    'Gefühle',
    'Beziehungen',
    'Zeitkompetenz',
    'Abgrenzung',
    'Gewaltfreie Kommunikation',
    'Konfliktklärung',
    'Externalisierung',
    'Gedanken',
    'Selbstvertrauen',
    'Selbstliebe',
    'Selbstachtung',
    'Rhythmus',
    'Beruf und Familie',
    'Burn Out',
  ],
  linkedBlogEntryIndexes: ['E16', 'E17', 'E18'],
}

export default BlogEntry
