import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/kompetenzstaerkung.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-07-31',
  year: '2016',
  month: '7',
  title: `Männerstrategien kennen und parieren`,
  teaser: `Wie Sie Männerstrategien durchschauen und selbstwirksam agieren können.`,
  url: 'maennerstrategien-kennen-und-parieren',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Durchsetzungskraft',
    'Kommunikationsfähigkeit',
    'Konfliktkompetenz',
    'Selbstbewusstsein',
    'Suggestivfragen',
    'Widerstand',
    'Wutanfall',
    'Kränkungen',
    'Abwertungen',
    'Drohungen',
    'Zeitdruck',
    'Anweisungen',
    'Schuldgefühle',
    'Gefühle',
    'Männerstrategien',
  ],
  linkedBlogEntryIndexes: ['E12', 'E15', 'E3'],
}

export default BlogEntry
