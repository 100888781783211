export type {
  BlogEntryData,
  FullBlogEntryData,
  BlogEntryMonth,
  BlogEntryYear,
  BlogEntryTag,
  BlogEntriesMap,
  BlogEntriesSlugMap,
  BlogEntriesIdMap,
  BlogEntriesTagMap,
  BlogPageProps,
  BlogTagPageProps,
} from './Types'

export { default as BlogEntry } from './BlogEntry'

import type {
  BlogEntriesMap,
  BlogEntriesSlugMap,
  BlogEntriesIdMap,
  BlogEntriesTagMap,
  BlogPageProps,
} from './Types'

import BlogEntry from './BlogEntry'

import compact from 'lodash/compact'
import each from 'lodash/each'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import values from 'lodash/values'

import E1 from './2015-07-30-warum-eigentlich-einen-extra-coach-fuer-frauen'
import E2 from './2015-08-18-wie-arbeitet-eigentlich-ein-systemischer-coach-therapeut'
import E3 from './2015-09-14-durch-systemisches-coaching-schaetze-finden-und-heben-1-9'
import E4 from './2015-09-29-durch-systemisches-coaching-schaetze-finden-und-heben-2-9'
import E5 from './2015-11-02-durch-systemisches-frauencoaching-schaetze-finden-und-heben-3-9'
import E6 from './2015-11-29-durch-systemisches-frauencoaching-schaetze-finden-und-heben-4-9'
import E7 from './2016-01-04-durch-systemisches-frauencoaching-schaetze-finden-und-heben-5-9'
import E8 from './2016-03-06-durch-systemisches-frauencoaching-schaetze-finden-und-heben-6-9'
import E9 from './2016-04-02-durch-systemisches-coaching-schaetze-finden-und-heben-7-9'
import E10 from './2016-05-01-durch-systemisches-frauencoaching-schaetze-finden-und-heben-8-9'
import E11 from './2016-06-12-durch-systemisches-frauencoaching-schaetze-finden-und-heben-9-9'
import E12 from './2016-07-02-berufung-und-beruf'
import E13 from './2016-07-31-maennerstrategien-kennen-und-parieren'
import E14 from './2016-11-05-balancekompetenzen'
import E15 from './2017-05-06-fundament-fuer-die-gleichberechtigung-von-frauen'
import E16 from './2017-12-03-methodenkoffer-das-systembrett'
import E17 from './2019-07-23-methodenkoffer-das-innere-kind-heilen'
import E18 from './2022-03-23-kleines-1x1-des-selbstwertgefuehls-und-der-selbstliebe'

export const ENTRIES_ID_MAP: BlogEntriesIdMap = {
  E1: new BlogEntry(E1), // 0
  E2: new BlogEntry(E2), // 1
  E3: new BlogEntry(E3), // 2
  E4: new BlogEntry(E4), // 3
  E5: new BlogEntry(E5), // 4
  E6: new BlogEntry(E6), // 5
  E7: new BlogEntry(E7), // 6
  E8: new BlogEntry(E8), // 7
  E9: new BlogEntry(E9), // 8
  E10: new BlogEntry(E10), // 9
  E11: new BlogEntry(E11), // 10
  E12: new BlogEntry(E12), // 11
  E13: new BlogEntry(E13), // 12
  E14: new BlogEntry(E14), // 13
  E15: new BlogEntry(E15), // 14
  E16: new BlogEntry(E16), // 15
  E17: new BlogEntry(E17), // 16
  E18: new BlogEntry(E18), // 17
}

export const ENTRIES: BlogEntry[] = map(
  ENTRIES_ID_MAP,
  (e: BlogEntry) => e
)

export const ENTRIES_MAP: BlogEntriesMap = reduce<
  BlogEntry, BlogEntriesMap
>(
  ENTRIES,
  (map, entry) => entry.addSelfToMap(map),
  {}
)

export const ENTRIES_SLUG_MAP = reduce<
  BlogEntry, BlogEntriesSlugMap
>(
  ENTRIES,
  (map, entry) => {
    map[entry.getSlugString()] = entry
    return map
  },
  {}
)

export const ENTRIES_TAGS_MAP = reduce<
  BlogEntry, BlogEntriesTagMap
>(
  ENTRIES,
  (map, entry) => {
    each(entry.getTags(), (tag) => {
      const t = kebabCase(tag)
      if (!map[t]) {
        map[t] = {
          tag,
          entries: []
        }
      }
      map[t].entries.push(entry)
    })

    return map
  },
  {}
)

export function getEntriesByYear(
  year: string,
): BlogEntry[] {
  if (!ENTRIES_MAP[year] || isEmpty(ENTRIES_MAP[year])) return []

  return compact(
    flatten(values(ENTRIES_MAP[year]))
  )
}

export function getEntrySlugsByYear(
  year: string,
): string[] {
  return compact(
    reduce<BlogEntry, string[]>(
      getEntriesByYear(year),
      (memo, entry) => [
        ...memo,
        entry.getSlugString()
      ],
      []
    )
  )
}

export function getEntriesByMonth(
  year: string,
  month: string,
): BlogEntry[] {
  const y = ENTRIES_MAP[year]

  if (!y || isEmpty(y)) return []
  if (!y[month] || isEmpty(y[month])) return []

  return ENTRIES_MAP[year][month] || []
}

export function getEntrySlugsByMonth(
  year: string,
  month: string,
): string[] {
  return reduce<BlogEntry, string[]>(
    getEntriesByMonth(year, month),
    (memo, entry) => [
      ...memo,
      entry.getSlugString()
    ],
    []
  )
}

export function getPagePropsBySlug(slug: string[]) {
  if (slug.length <= 0) return {}

  const data: BlogPageProps = { type: 'year' }
  const s: string[] = [...slug]
  let key: string | undefined = undefined

  // load year
  key = s.shift()
  if (!key) return data
  const year = ENTRIES_MAP[key]
  data.year = key
  data.yearMonths = map(ENTRIES_MAP[key], (_v, month) => month)

  // load month
  key = s.shift()
  if (!key) return data
  const month = year[key] || year[`0${key}`]
  data.type = 'month'
  data.month = key
  data.monthUrls = map(year[key], (v) => v.getUrl())

  // load entry
  key = s.shift()
  if (!key) return data
  data.type = 'entry'
  const entry = find(
    month,
    (e: BlogEntry) => e.getUrl() === key
  )
  data.blogEntryData = entry?.toJSON()

  // Final return
  return data
}
