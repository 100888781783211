import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/fragezeichen.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2019-07-23',
  year: '2019',
  month: '7',
  title: `Methodenkoffer: Das innere Kind heilen`,
  teaser: `Ein festes Lebensfundament bauen: Das innere Kind heilen.`,
  url: 'methodenkoffer-das-innere-kind-heilen',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Lügen',
    'Narzissmus',
    'Vermeidung',
    'Rückzug',
    'Flucht',
    'Kind bleiben',
    'Attacke',
    'Angriff',
    'Kontrollstreben',
    'Machtstreben',
    'Helfersyndrom',
    'Überanpassung',
    'Harmoniestreben',
    'Opferdenken',
    'Projektion',
    'Verdrängung',
    'Schutzstrategien',
    'inneres Kind',
  ],
  linkedBlogEntryIndexes: ['E17', 'E14', 'E3']
}

export default BlogEntry
