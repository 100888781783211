import type {
  BlogEntryData,
  BlogEntriesMap,
  FullBlogEntryData,
} from './Types'

import omit from 'lodash/omit'

class BlogEntry {
  data: FullBlogEntryData

  constructor(data: FullBlogEntryData) {
    this.data = data
  }

  toFullJSON(): FullBlogEntryData {
    return this.data || {}
  }

  toJSON(): BlogEntryData {
    return omit(this.data, 'BlogContent') as BlogEntryData
  }

  getYear(): string {
    return this.data.year
  }

  getMonth(): string {
    return this.data.month
  }

  getUrl(): string {
    return this.data.url
  }

  getSlug(): string[] {
    return [
      encodeURIComponent(this.getYear()),
      encodeURIComponent(this.getMonth()),
      encodeURIComponent(this.getUrl()),
    ]
  }

  getTags(): string[] {
    return this.data.tags || []
  }

  getSlugString(): string {
    return this.getSlug().join('/')
  }

  addSelfToMap(map: BlogEntriesMap) {
    const y = this.getYear()
    const m = this.getMonth()

    if (!map[y]) map[y] = {}
    if (!map[y][m]) map[y][m] = []

    map[y][m].push(this)

    return map
  }
}

export default BlogEntry
