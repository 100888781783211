import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_7.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-04-02',
  year: '2016',
  month: '4',
  title: `Durch systemisches Coaching Schätze finden und heben (7/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 7: Beziehungskompetenzen gezielt nutzen. Wie systemisches Coaching sie dabei unterstützen kann, ihre Ziele zu erreichen.`,
  url: 'durch-systemisches-coaching-schaetze-finden-und-heben-7-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Kommunikation',
    'Emotionen',
    'Wertschätzung',
    'Sebstwerdung',
    'Anerkennung',
    'Angst',
    'Konflikte',
    'Krisen',
    'Beziehungskompetenz',
    'Beziehung',
    'Coaching',
  ],
  linkedBlogEntryIndexes: ['E10', 'E11', 'E2'],
}

export default BlogEntry
