import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_8.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2016-05-01',
  year: '2016',
  month: '5',
  title: `Durch systemisches Coaching Schätze finden und heben (8/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 8: Dankbarkeits- und Glückskompetenz genießen.`,
  url: 'durch-systemisches-frauencoaching-schaetze-finden-und-heben-8-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Sonne',
    'Selbstwirksamkeit',
    'Sinne',
    'Begegnen',
    'Erleben',
    'Konsumenten',
    'Selbstwertgefühl',
    'Mut',
    'Freiheit',
    'Glück',
    'Dankbarkeit',
  ],
  linkedBlogEntryIndexes: ['E11', 'E12', 'E13'],
}

export default BlogEntry
