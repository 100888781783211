import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_3.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-11-02',
  year: '2015',
  month: '11',
  title: `Durch systemisches Coaching Schätze finden und heben (3/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 3: Selbstmanagementkompetenzen nutzen. Wie systemisches Coaching sie dabei unterstützen kann, Ihre persönlichen Selbstmanagementkompetenzen effektiv zu nutzen.`,
  url: 'durch-systemisches-coaching-schaetze-finden-und-heben-3-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Coaching',
    'Probleme',
    'Fragen',
    'Herausforderung',
    'Selbstdisziplin',
    'Zeitmanagement',
    'Planung',
    'Ressourcen',
    'Kompetenzen',
    'Selbstmanagement',
    'FrauenCoaching',
  ],
  linkedBlogEntryIndexes: ['E6', 'E7', 'E1'],
}

export default BlogEntry
