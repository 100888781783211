import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/selbstbestimmung.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-07-30',
  year: '2015',
  month: '7',
  title: `Warum muss es ein Extra-Coach für Frauen sein?`,
  teaser: `Ganz einfach: Weil sich Frauen tägtäglich mit anderen Strukturen und Bedingungen auseinandersetzen müssen als Männer. Das kann man sich vielleicht so ähnlich vorstellen wie die Suche nach einem individuellen Kleid für einen besonderen Anlass. Da wird Frau den Auftrag wohl eher einer Damenmaßschneiderin erteilen und nicht dem Herrenmaßschneider. Nur die Damenmaßschneiderin ist Spezialistin für die weiblichen Proportionen und kennt die speziellen Bedürfnisse aus eigener Erfahrung. So ist das bei einem weiblichen FrauenCoach auch.`,
  url: 'warum-eigentlich-einen-extra-coach-fuer-frauen',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Familie',
    'Politik und Wirtschaft',
    'Führungspositionen',
    'Frauen in Deutschland',
    'Wozu ein Frauen-Coach?',
  ],
  linkedBlogEntryIndexes: ['E3', 'E13', 'E2']
}

export default BlogEntry
