import type { FullBlogEntryData } from 'BlogEntries/Types'
import blogContentDynamic from '../blogContentDynamic'
import img from 'assets/images/content/schatztruhe_2.jpg'

const BlogEntry: FullBlogEntryData = {
  date: '2015-09-29',
  year: '2015',
  month: '9',
  title: `Durch systemisches Coaching Schätze finden und heben (2/9)`,
  teaser: `Ein Exkurs in 9 Etappen. Schatz 2: Lebensziele kennen und verfolgen. Wie systemisches Coaching Sie dabei unterstützen kann, Ihre persönlichen Ziele zu finden, sich darauf zu fokussieren und sie konsequent zu verfolgen. Für ein glückliches Leben in Balance.`,
  url: 'durch-systemisches-coaching-schaetze-finden-und-heben-2-9',
  image: img,
  ogImage: img,
  BlogContent: blogContentDynamic(import(`./BlogContent`)),
  tags: [
    'Realist',
    'Kritiker',
    'Phantasie',
    'Träumer',
    'Verantwortung',
    'Frauen',
    'Werte',
    'Herausforderungen',
    'Ziele',
  ],
  linkedBlogEntryIndexes: ['E5', 'E6', 'E16'],
}

export default BlogEntry
